
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class PageTitle extends Vue {
  @Prop({}) icon!: string;
  @Prop({}) title!: string;
  @Prop({ default: false }) loading!: boolean;
}
