
import Component, { mixins } from "vue-class-component";
import { Prop, PropSync } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class BarSearch extends mixins(StyleCheck) {
  @PropSync("search", { type: String })
  syncedSearch!: string;
  @Prop({ default: false })
  readonly!: boolean;
  @Prop({ required: true })
  searchCallback!: () => Promise<void>;
  @Prop()
  label!: string;
  async onSearch(clear: boolean) {
    await this.$nextTick();
    if (this.syncedSearch == null || clear === true) {
      this.syncedSearch = "";
    }
    this.searchCallback();
  }
}
