
import { FormValidations } from "@/mixins/form-validations";
import { CustomDate } from "@/mixins/custom-date";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import CommentToolbar from "@/components/Layout/Common/CommentToolbar.vue";
import CommentActions from "@/components/Layout/Common/CommentActions.vue";
import { Notification } from "@/models/notification.interface";
import { StyleCheck } from "@/mixins/style-check";
import BarSearch from "@/components/Input/BarSearch.vue";
@Component({
  components: {
    PageTitle,
    Loader,
    Empty,
    CommentToolbar,
    CommentActions,
    BarSearch,
  },
})
export default class CommentsOnProducts extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  $refs!: {
    roleForm: HTMLFormElement;
  };
  loading = false;
  commentsLoader = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  search = "";
  options = {};

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async updateComments() {
    await this.getAllProductComments(
      this.$route.params.page,
      this.pagination.itemsPerPage,
      this.search
    );
  }

  private get totalPages() {
    const total =
      this.pagination.totalItems /
      Number(process.env.VUE_APP_DEFAULT_PAGINATION);
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  @Watch("$route.params", { deep: true })
  private async handler() {
    this.commentsLoader = true;
    await this.getAllProductComments(
      this.$route.params.page,
      this.pagination.itemsPerPage,
      this.$route.params.text
    );
    this.commentsLoader = false;
  }

  private changePage() {
    this.navigate("/staff/interaction/products/" + this.pagination.page);
  }

  private async searchCommentByText() {
    this.commentsLoader = true;

    await this.getAllProductComments(
      this.pagination.page.toString(),
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.commentsLoader = false;
  }

  private async getAllProductComments(
    page: string,
    size: number,
    text = ""
  ): Promise<void> {
    await this.$store
      .dispatch("comments/getAllProductComments", { page, size, text })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.cop-error1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.commentsLoader = true;
    await this.getAllProductComments(
      this.$route.params.page,
      this.pagination.itemsPerPage,
      this.search
    );
    this.commentsLoader = false;
  }

  private get comments() {
    let response = this.$store.getters["comments/getProductComments"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.comments) {
      return response.comments;
    } else {
      return [];
    }
  }

  resetValidations() {
    this.resetFormValidations([this.$refs.roleForm]);
  }
}
